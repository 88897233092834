
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DTableHeader } from '@/models';
import Tooltip from '@/components/shared/Tooltip.vue';

@Component({
  name: 'SimpleHeaderType',
  components: {
    Tooltip,
  },
})
export default class SimpleHeaderType extends Vue {
  @Prop() data: DTableHeader
}
