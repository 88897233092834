
import { Component, Mixins } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { Location } from 'vue-router';

import Header from '@/components/Header/Header.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import DynamicTable from '@/components/DynamicTable/DynamicTable.vue';
import SearchInput from '@/components/shared/SearchInput.vue';
import Spinner from '@/components/shared/Spinner.vue';
import Footer from '@/components/Footer/Footer.vue';
import Paginator from '@/components/shared/Paginator.vue';
import ReportFilter from '@/components/shared/ReportFilter.vue';
import DeleteConfirm from '@/components/AssignmentObject/DeleteConfirm.vue';
import Download from '@/assets/icons/Download.svg';

import { AssignmentObjectService, DynamicTableService, ModalService } from '@/services';

import {
  AssignmentObjectModel,
  DTable,
  DTableBody,
  DTableHeader,
  RouteName,
  FloatingMenuItem,
  ModalTipos,
  ModalDataTransfer,
  DataConfirmation,
  DTableHeaderBody,
  DTableEvents,
  ReportFilterEvents,
  ReportFilterData,
} from '@/models';

import { Pagination, QueryParamsModel } from '@/models/api';

import PublicationModalFlow from '@/mixins/PublicationModalFlow';
import FloatingMenuFlow from '@/mixins/FloatingMenuFlow';
import { DownloadUtils, EventBus } from '@/utils';

const { ASSIGNMENT_OBJECT_ITEM } = DTableBody;
const { SIMPLE_HEADER_TYPE } = DTableHeaderBody;
const { ASSIGNMENT_OBJECT_CREATE, ASSIGNMENT_OBJECT_EDIT } = RouteName;
const { REQUEST_STARTED, REQUEST_ENDED } = DTableEvents;
const {
  INFORMATIVE, OVERLAY, CONFIRMACAO, FILTER_REPORT,
} = ModalTipos;
const { ALL_SELECTED } = ReportFilterEvents;

@Component({
  name: 'AssignmentObjectManager',
  components: {
    Header,
    ButtonDefault,
    DynamicTable,
    SearchInput,
    Spinner,
    Footer,
    Download,
    Paginator,
  },
  computed: {
    ...mapGetters('user', {
      canEditNetwork: 'canEditNetwork',
    }),
    ...mapGetters('assignmentObjectList', {
      assignmentObjectList: 'assignmentObjectList',
      pagination: 'pagination',
    }),
  },
  methods: {
    ...mapActions('assignmentObject', {
      resetAssignmentObject: 'resetAssignmentObject',
    }),
    ...mapActions('assignmentObjectList', {
      getList: 'getList',
    }),
  },
})

export default class AssignmentObjectManager extends Mixins(
  PublicationModalFlow,
  FloatingMenuFlow,
) {
  public ASSIGNMENT_OBJECT_CREATE = ASSIGNMENT_OBJECT_CREATE;

  public pagination: Pagination<AssignmentObjectModel>
  public disable = false;

  private deleteId: number;
  private assignmentObjectList: AssignmentObjectModel[]
  private searchKey = '';
  private debounce: number;
  private resetAssignmentObject: () => void;
  private getList: (params: QueryParamsModel) => Promise<void>
  private reportFilter: ReportFilterData

  private headerData: DTableHeader[] = [{
    hasTooltip: false,
    hasFilter: false,
    width: 3,
    grow: false,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'ID',
    hasTooltip: false,
    hasFilter: false,
    width: 16,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'CURSO',
    hasTooltip: false,
    hasFilter: false,
    width: 31,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'DISCIPLINA',
    hasTooltip: false,
    hasFilter: false,
    width: 31,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'STATUS',
    hasTooltip: false,
    hasFilter: false,
    width: 16,
    grow: false,
    type: SIMPLE_HEADER_TYPE,
  }, {
    hasTooltip: false,
    hasFilter: false,
    width: 3,
    grow: false,
    type: SIMPLE_HEADER_TYPE,
  }];

  public tableData: DTable = {
    header: this.headerData,
    body: ASSIGNMENT_OBJECT_ITEM,
    data: [],
    noDataText: 'Sem resultados encontrados.',
  };

  private filterData: DataConfirmation = {
    component: ReportFilter,
    allowText: 'BAIXAR',
    denyText: 'CANCELAR',
    allowDisabled: true,
    confirmEvent: FILTER_REPORT,
  };

  get disableSearchButton(): boolean {
    const searchQuery = this.$route.query.search;

    return (!this.searchKey.length && !searchQuery) || this.searchKey === searchQuery;
  }

  mounted() {
    const { search, page } = this.$route.query;

    if (search) this.updateSearchKey(search as string);

    this.subscribeToEvents();
    this.getObjectList(search as string, Number(page));
    this.subscribeToEvents();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }
  private subscribeToEvents() {
    EventBus.$on(ALL_SELECTED, this.enableDownload);
    EventBus.$on(FILTER_REPORT, this.download);
    EventBus.$on(REQUEST_STARTED, this.requestStarted);
    EventBus.$on(REQUEST_ENDED, this.requestEnded);
  }

  private unsubscribeToEvents() {
    EventBus.$off(ALL_SELECTED, this.enableDownload);
    EventBus.$off(FILTER_REPORT, this.download);
    EventBus.$off(REQUEST_STARTED, this.requestStarted);
    EventBus.$off(REQUEST_ENDED, this.requestEnded);
  }

  private requestStarted(): void{
    this.disable = true;
  }

  private requestEnded(): void{
    this.disable = false;
  }

  public onEdit(item: FloatingMenuItem) {
    this.goTo(ASSIGNMENT_OBJECT_EDIT, item.id.toString());
  }

  public onDelete(item: FloatingMenuItem) {
    this.deleteAssignmentObject(item.id);
  }

  private getObjectList(termo?: string, page?: number) {
    clearTimeout(this.debounce);
    DynamicTableService.requestStarted();
    this.tableData.data = [];

    this.getList({ termo, page })
      .then(() => { this.tableData.data = this.assignmentObjectList; })
      .catch(console.error)
      .finally(() => { DynamicTableService.requestEnded(); });
  }

  private deleteAssignmentObject(id: number) {
    const object = (this.tableData.data as AssignmentObjectModel[])
      .find((assignmentObjectItem: AssignmentObjectModel) => assignmentObjectItem.id === id);

    this.deleteId = id;

    if (object?.deletable) {
      this.deleteTrabalho(object.discipline.name);
    } else {
      this.notDeletableMessageModal();
    }
  }

  public goTo(route: RouteName, params?: string) {
    const routeName: Location = {
      name: route,
    };

    if (params) routeName.params = { trabalhoId: params };
    else this.resetAssignmentObject();

    this.$router.push(routeName);
  }

  private notDeletableMessageModal() {
    const modalData: ModalDataTransfer = {
      fechavel: true,
      component: INFORMATIVE,
      props: {
        // eslint-disable-next-line max-len
        phrase: 'Não foi possível realizar a exclusão pois\nexistem Assignments publicados ou\nsalvos como rascunho.',
      },
    };

    ModalService.abrirModal(modalData);
  }

  private deleteTrabalho(disciplina: string) {
    const deleteConfirm: DataConfirmation = this.confirmData(
      'EXCLUIR',
      'CANCELAR',
      DeleteConfirm,
      disciplina,
    );
    this.setLoadingPhrase('Excluindo objeto de networking...');

    setTimeout(() => {
      this.openModal(CONFIRMACAO, deleteConfirm, true);
    });
  }

  private requestDeleteTrabalho(trabId: number) {
    AssignmentObjectService
      .deleteObject(trabId)
      .then((id: number) => this.removeTrabalhoFromTheList(id))
      .catch(console.error)
      .finally(() => ModalService.fecharModal());
  }

  private removeTrabalhoFromTheList(id: number) {
    this.$set(this.tableData, 'data', (this.tableData.data as AssignmentObjectModel[])
      .filter((assignmentObjectItem: AssignmentObjectModel) => assignmentObjectItem.id !== id));
  }

  public publishRequest() {
    this.requestDeleteTrabalho(this.deleteId);
  }

  public reportFilterModal() {
    this.filterData.allowDisabled = true;
    this.openModal(FILTER_REPORT, this.filterData, true);
  }

  private enableDownload(data: ReportFilterData) {
    this.filterData.allowDisabled = false;
    this.reportFilter = data;
  }

  private download(download: boolean) {
    if (!download) return;

    const modalData: ModalDataTransfer = {
      fechavel: false,
      component: OVERLAY,
      overlayDark: true,
      props: {
        phrase: 'Aguarde enquanto seu relatório está\n sendo preparado para download.',
      },
    };

    setTimeout(() => {
      ModalService.abrirModal(modalData);
    });

    AssignmentObjectService
      .reportDownload(this.reportFilter)
      .then((response) => DownloadUtils.download(response))
      .finally(() => ModalService.fecharModal());
  }

  public search() {
    const searchQuery = this.$route.query.search;

    if (this.searchKey === searchQuery) return;

    this.getObjectList(this.searchKey);
    this.pushRoute({ search: this.searchKey });
  }

  public updateSearchKey(searchKey: string) {
    this.searchKey = searchKey;
  }

  public paginate(page: number) {
    this.getObjectList(this.searchKey, page);
    const query: {search?: string; page?: string} = {
      page: String(page),
    };

    if (this.searchKey) query.search = this.searchKey;

    this.pushRoute(query);
  }

  private pushRoute(query: {search?: string; page?: string}) {
    const { search, page } = query;
    const searchQuery = this.$route.query.search;
    const pageQuery = this.$route.query.page;
    const route = { path: this.$route.path, query };

    if (search === searchQuery && page === pageQuery) return;

    if (!search) delete route.query.search;
    if (!page) delete route.query.page;

    this.$router.push(route);
  }
}
