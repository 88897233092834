
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import VerticalEllipsis from '@/assets/icons/VerticalEllipsis.svg';
import ChevronDown from '@/assets/icons/ChevronDown.svg';

import Pill from '@/components/shared/Pill.vue';
import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';

import {
  AssignmentObjectModel,
  ObjectStatusText,
  ObjectStatusColor,
  DTableHeader,
  IconsList,
  DTable,
  DTableBody,
  FloatingMenuEvents,
  RouteName,
  FloatingMenuModel,
  FloatingMenuContent,
  FloatingMenuPosition,
  DTableHeaderBody,
} from '@/models';

import { AccordionUtils } from '@/utils/AccordionUtils';

const { LEFT } = FloatingMenuPosition;
const { LINK_TYPE, SIMPLE_TYPE } = FloatingMenuContent;
const { QUESTION } = IconsList;
const { ASSIGNMENT_OBJECT_TURMA } = DTableBody;
const { SIMPLE_HEADER_TYPE } = DTableHeaderBody;
const { DELETE } = FloatingMenuEvents;
const { ASSIGNMENT_OBJECT_EDIT } = RouteName;

@Component({
  name: 'AssignmentObjectItem',
  components: {
    ChevronDown,
    Pill,
    FloatingMenu,
    VerticalEllipsis,
    DynamicTable: () => import('@/components/DynamicTable/DynamicTable.vue'),
  },
  computed: {
    ...mapGetters('user', {
      canEditNetwork: 'canEditNetwork',
    }),
  },
})

export default class AssignmentObjectItem extends Vue {
  @Prop() data: AssignmentObjectModel;
  @Prop({ default: false }) editMode: boolean;

  $refs: {
    expadableElement: HTMLDivElement;
  };

  public pillColor: ObjectStatusColor;
  public pillText: ObjectStatusText;
  public debounce: number;

  public expanded = false;

  public menu: FloatingMenuModel = {
    data: [],
    tip: true,
    hover: true,
    position: LEFT,
  };

  public classesTableHeader: DTableHeader[] = [{
    hasTooltip: false,
    hasFilter: false,
    grow: false,
    width: 3,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'Turma',
    hasTooltip: false,
    hasFilter: false,
    width: 16,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'Data de Publicação',
    hasTooltip: true,
    hasFilter: false,
    tooltipText: 'Data em que o objeto foi publicado na turma',
    tooltipIcon: QUESTION,
    width: 31,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'Data de Entrega\nRegular',
    hasTooltip: false,
    hasFilter: false,
    width: 31,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'Data de Entrega\nRecuperação',
    hasTooltip: false,
    hasFilter: false,
    width: 16,
    type: SIMPLE_HEADER_TYPE,
  }, {
    hasTooltip: false,
    hasFilter: false,
    grow: false,
    width: 3,
    type: SIMPLE_HEADER_TYPE,
  }];

  public classesTable: DTable = {
    header: this.classesTableHeader,
    body: ASSIGNMENT_OBJECT_TURMA,
    data: [],
    noDataText: 'Nenhuma turma encontrada',
  };

  get objectId(): string {
    return this.data.id && this.data.createdAt
      ? `000${this.data.id}`.slice(-3) + String(this.data.createdAt.getFullYear()).slice(-2)
      : '—';
  }

  created() {
    this.pillColor = ObjectStatusColor[this.data.status as keyof typeof ObjectStatusColor];
    this.pillText = ObjectStatusText[this.data.status as keyof typeof ObjectStatusText];
    this.classesTable.data = this.data.classes;
  }

  mounted() {
    this.buildMenu();
  }

  private buildMenu() {
    this.menu.data = [{
      type: LINK_TYPE,
      id: this.data.id,
      text: 'Editar',
      name: ASSIGNMENT_OBJECT_EDIT,
      params: { trabalhoId: this.data.id },
    }];

    // TODO: Descomentar o if se não for exibir a opção de excluir quando for deletable false
    // if (this.data.deletable) {
    this.menu.data.push({
      type: SIMPLE_TYPE,
      id: this.data.id,
      text: 'Excluir',
      action: DELETE,
    });
    // }
  }

  public toggleExpansion() {
    this.expanded = !this.expanded;
    AccordionUtils.toggleExpandableElement(this.$refs.expadableElement, this.expanded, 500);
  }
}
