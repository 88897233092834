
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import VerticalEllipsis from '@/assets/icons/VerticalEllipsis.svg';

import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';

import {
  FloatingMenuEvents,
  FloatingMenuItem,
  AssignmentObjectModel,
  RouteName,
  FloatingMenuPosition,
  FloatingMenuContent,
  FloatingMenuModel,
} from '@/models';

import { DateUtils } from '@/utils';

const { LEFT } = FloatingMenuPosition;
const { DELETE } = FloatingMenuEvents;
const { ASSIGNMENT_OBJECT_EDIT } = RouteName;
const { SIMPLE_TYPE, LINK_TYPE } = FloatingMenuContent;

@Component({
  name: 'AssignmentObjectSingleItem',
  components: {
    FloatingMenu,
    VerticalEllipsis,
  },
  computed: {
    ...mapGetters('user', {
      canEditNetwork: 'canEditNetwork',
    }),
  },
})

export default class AssignmentObjectSingleItem extends Vue {
  @Prop() data: AssignmentObjectModel;

  public canEditNetwork: boolean;

  public menuItems: FloatingMenuModel = {
    tip: true,
    hover: true,
    position: LEFT,
    data: [{
      type: LINK_TYPE,
      id: 0,
      text: 'Editar',
      name: ASSIGNMENT_OBJECT_EDIT,
    }, {
      type: SIMPLE_TYPE,
      id: 0,
      text: 'Excluir',
      action: DELETE,
    }],
  };

  get className(): string {
    return this.data.class?.name ?? '—';
  }

  get course(): string {
    return this.data.course?.name ?? '—';
  }

  get deliveryDate(): string {
    if (!this.data.class?.deliveryDate) return '—';
    return DateUtils.toString(this.data.class?.deliveryDate as Date);
  }

  get discipline(): string {
    return this.data.discipline?.name ?? '—';
  }

  get id(): string {
    return this.data.id && this.data.createdAt
      ? `000${this.data.id}`.slice(-3) + String(this.data.createdAt.getFullYear()).slice(-2)
      : '—';
  }

  get publicationDate(): string {
    if (!this.data.class?.publicationDate) return '—';
    return DateUtils.toString(this.data.class?.publicationDate as Date);
  }

  get recoveryDate(): string {
    if (!this.data.class?.recoveryDeliveryDate) return '—';
    return DateUtils.toString(this.data.class?.recoveryDeliveryDate as Date);
  }

  mounted() {
    if (this.data) {
      this.menuItems.data = this.menuItems.data
        .map((item: FloatingMenuItem) => {
          const itm = item;
          itm.id = this.data.id;
          itm.params = { trabalhoId: this.data.id };
          return itm;
        });
    }
  }
}
