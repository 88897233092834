
import { Component, Vue } from 'vue-property-decorator';

import SortUp from '@/assets/icons/SortUp.svg';
import SortDown from '@/assets/icons/SortDown.svg';

@Component({
  name: 'SortUpIcon',
  components: {
    SortUp,
    SortDown,
  },
})
export default class SortUpIcon extends Vue {

}
