
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import {
  DTableHeader,
  FilterData,
  FilterToField,
  FilterToHeader,
  FloatingMenuContent,
  FloatingMenuEvents,
  FloatingMenuItem,
  FloatingMenuModel,
} from '@/models';

import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import SortUpIcon from '@/components/shared/SortUpIcon.vue';
import SortDownIcon from '@/components/shared/SortDownIcon.vue';

import { EventBus, FilterUtils } from '@/utils';

const {
  CHECK,
  SELECT,
  INPUT,
  BUTTON_CLICKED,
} = FloatingMenuEvents;

const {
  SELECT_TYPE,
  CHECKBOX_TYPE,
  DIVIDER_TYPE,
  INPUT_TYPE,
  WRAPPER_TYPE,
  BUTTON_TYPE,
  SPINNER_TYPE,
  TEXT_TYPE,
} = FloatingMenuContent;

@Component({
  name: 'FilterHeaderType',
  components: {
    FloatingMenu,
    Tooltip,
  },
})
export default class FilterHeaderType extends Vue {
  @Prop() data: DTableHeader;

  public FilterToHeader = FilterToHeader;
  private filterToApply: FilterData;

  private filtersItems: FloatingMenuItem[] = [{
    type: CHECKBOX_TYPE,
    id: 5,
    text: 'Selecionar tudo',
    checked: false,
    action: CHECK,
    keyString: this.keyString,
  }, {
    type: DIVIDER_TYPE,
    id: 6,
    text: '',
  }, {
    type: SPINNER_TYPE,
    id: 7,
    text: '',
  }];

  private selectItems: FloatingMenuItem[] = [{
    type: SELECT_TYPE,
    id: 0,
    text: 'Classificar ordem crescente',
    selected: false,
    action: SELECT,
    icon: SortUpIcon,
  }, {
    type: SELECT_TYPE,
    id: 1,
    text: 'Classificar ordem decrescente',
    selected: false,
    action: SELECT,
    icon: SortDownIcon,
  }];

  private filterData: FloatingMenuModel = {
    showMenu: false,
    hover: false,
    tip: false,
    keyString: '',
    openOnlyOne: true,
    requestData: true,
    data: [
      ...this.selectItems,
      {
        type: INPUT_TYPE,
        id: 2,
        text: 'Pesquisar',
        action: INPUT,
      }, {
        type: WRAPPER_TYPE,
        id: 3,
        text: '',
        items: this.filtersItems,
      }, {
        type: BUTTON_TYPE,
        id: 4,
        text: 'Aplicar',
        action: BUTTON_CLICKED,
      }],
  };

  private initialFilters: FloatingMenuItem[] = [];

  private noFilter: FloatingMenuItem[] = [{
    id: 5,
    type: TEXT_TYPE,
    text: 'Nenhum filtro disponível',
  }];

  mounted() {
    this.subscribeToEvents();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }

  private subscribeToEvents() {
    EventBus.$on(CHECK, this.check);
  }

  private unsubscribeToEvents() {
    EventBus.$off(CHECK, this.check);
  }

  get keyString(): string {
    return this.data.text ?? '';
  }

  get filter(): FloatingMenuModel {
    this.filterData.keyString = this.data.text;
    return this.filterData;
  }

  @Watch('data')
  public populateFilter(data: DTableHeader) {
    const { filter, desc } = this.$route.query;
    let appliedFilter: FilterData = {};
    const filterKey = FilterToField[data.text as keyof typeof FilterToField];

    if (!data.filterItems) {
      this.filterData.data[3].items = this.filtersItems;
      return;
    }

    if (data.filterItems && data.filterItems[filterKey] && data.filterItems[filterKey].length) {
      const initial = this.filtersItems.slice(0, 2);
      const items: FloatingMenuItem[] = [];

      if (filter || desc) appliedFilter = FilterUtils.toObject(filter as string);
      data.filterItems[filterKey]
        .forEach((item: string | number | null, idx: number) => {
          items.push({
            type: CHECKBOX_TYPE,
            id: idx + 7,
            text: FilterUtils.formatFilters(this.keyString, item),
            checked: (appliedFilter[filterKey]?.filters as string[])?.includes(String(item))
              ?? false,
            action: CHECK,
            keyString: this.keyString,
            textWrap: String(item).length > 45,
            value: String(item),
          });
        });

      initial[0].checked = !items.filter((item: FloatingMenuItem) => !item.checked).length;

      if (typeof appliedFilter[filterKey]?.desc === 'boolean') {
        const selectIndex = appliedFilter[filterKey]?.desc === false ? 0 : 1;
        this.filterData.data[selectIndex].selected = true;
      } else {
        this.filterData.data[0].selected = false;
        this.filterData.data[1].selected = false;
      }

      this.filterData.data[3].items = [...initial, ...items];
      this.initialFilters = this.filterData.data[3].items;
    } else {
      this.filterData.data[3].items = [...this.noFilter];
    }
  }

  private select(item: FloatingMenuItem) {
    this.selectItems = this.selectItems.map((selectItem: FloatingMenuItem) => {
      const sItem = selectItem;
      sItem.selected = sItem.id === item.id ? item.selected : false;
      return sItem;
    });
  }

  private input(value: string) {
    const initial = this.initialFilters
      .filter((item: FloatingMenuItem, idx: number) => (
        idx <= 1 || item.text.toUpperCase().includes(value.toUpperCase())
      ));
    this.filterData.data[3].items = initial.length > 2 ? [...initial] : [...this.noFilter];
  }

  private check({ keyString, item }: {keyString: string; item: FloatingMenuItem}) {
    if (keyString !== this.keyString) return;

    const items = this.filterData.data[3].items as FloatingMenuItem[];
    const { checked, id } = item;

    if (id === 5) {
      items.forEach((it: FloatingMenuItem) => {
        const i = it;
        if (i.id !== 6) i.checked = checked;
      });
    } else {
      items[id - 5].checked = checked;
      const allChecked = items
        .filter((filter: FloatingMenuItem) => filter.checked)
        .length === items.length - (checked ? 2 : 1);

      items[0].checked = allChecked;
    }
  }

  private buttonClicked() {
    const { data } = this.filterData;
    const { items } = data[3];
    const [cresc, desc] = data;
    const paramsFiltersKey = FilterToField[this.keyString as keyof typeof FilterToField] as string;

    this.filterToApply = {
      [paramsFiltersKey]: {
        filters: (items as FloatingMenuItem[])
          .reduce((acc: string[], cur: FloatingMenuItem, idx: number) => {
            const f = acc;
            if (idx > 1 && (cur.checked || (items as FloatingMenuItem[])[0].checked)) {
              f.push(cur.value as string);
            }
            return f;
          }, []),
      },
    };

    if (cresc.selected) this.filterToApply[paramsFiltersKey].desc = false;
    if (desc.selected) this.filterToApply[paramsFiltersKey].desc = true;

    this.$emit('filter', this.filterToApply);
  }

  public filterEvent({
    event, item, value,
  }: {
    index: number;
    keyString: string;
    event: FloatingMenuEvents;
    item: FloatingMenuItem;
    value: string;
  }) {
    switch (event) {
      case SELECT:
        this.select(item);
        break;
      case INPUT:
        this.input(value);
        break;
      case BUTTON_CLICKED:
        this.buttonClicked();
        break;
      default:
        break;
    }
  }
}
