import { render, staticRenderFns } from "./DeleteConfirm.vue?vue&type=template&id=56631028&scoped=true"
import script from "./DeleteConfirm.vue?vue&type=script&lang=ts"
export * from "./DeleteConfirm.vue?vue&type=script&lang=ts"
import style0 from "./DeleteConfirm.vue?vue&type=style&index=0&id=56631028&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56631028",
  null
  
)

export default component.exports