
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import Turmas from '@/components/DynamicTable/DynamicTableTypes/Turmas.vue';
import DeliveryGrade from '@/components/DynamicTable/DynamicTableTypes/DeliveryGrade.vue';
import AssignmentObjectItem
  from '@/components/DynamicTable/DynamicTableTypes/AssignmentObjectItem.vue';
import AssignmentObjectSingleItem
  from '@/components/DynamicTable/DynamicTableTypes/AssignmentObjectSingleItem.vue';
import AssignmentObjectTurma
  from '@/components/DynamicTable/DynamicTableTypes/AssignmentObjectTurma.vue';
import Spinner from '@/components/shared/Spinner.vue';
import NoData from '@/components/DynamicTable/DynamicTableTypes/NoData.vue';

import { DTableBody } from '@/models';

const {
  ASSIGNMENT_OBJECT_ITEM,
  ASSIGNMENT_OBJECT_SINGLE_ITEM,
  ASSIGNMENT_OBJECT_TURMA,
  DELIVERY_GRADE,
  NO_DATA,
  SPINNER,
  TURMAS,
} = DTableBody;

const tableBody: {
  [index: string]:
  VueConstructor;
} = {
  [ASSIGNMENT_OBJECT_ITEM]: AssignmentObjectItem,
  [ASSIGNMENT_OBJECT_SINGLE_ITEM]: AssignmentObjectSingleItem,
  [ASSIGNMENT_OBJECT_TURMA]: AssignmentObjectTurma,
  [DELIVERY_GRADE]: DeliveryGrade,
  [NO_DATA]: NoData,
  [SPINNER]: Spinner,
  [TURMAS]: Turmas,
};

@Component({
  name: 'DynamicTableBodyFactory',
})
export default class DynamicTableBodyFactory extends Vue {
  public static getTableBody(type: DTableBody): VueConstructor {
    return tableBody[type];
  }
}
