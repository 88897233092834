
import { Component, Prop, Vue } from 'vue-property-decorator';

import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import MaskInput from '@/components/shared/MaskInput.vue';
import ToggleSwitch from '@/components/shared/ToggleSwitch.vue';

import { AssignmentObjectEvents, Class, DateType } from '@/models';

import { EventBus } from '@/utils/EventBus';
import { DateUtils } from '@/utils/DateUtils';

const daysOffset = 1;
const { INVALID_DATE } = AssignmentObjectEvents;
const { DELIVERY, RECOVERY } = DateType;

@Component({
  name: 'Turmas',
  components: {
    CheckboxInput,
    ToggleSwitch,
    Tooltip,
    MaskInput,
  },
})
export default class Turmas extends Vue {
  @Prop() data: Class;
  @Prop({ default: false }) editMode: boolean;

  private currentDeliveryDate = '';
  private recoveryDeliveryDate = '';
  private initialRecoveryDeliveryDate = '';
  private invalidDate = false;
  private debounce: number;
  public DateType = DateType;

  get deliveryDate(): string {
    return this.currentDeliveryDate
      || DateUtils.toString(this.data?.deliveryDate as Date)
      || '—';
  }

  set deliveryDate(date: string) {
    this.currentDeliveryDate = date;
  }

  get publicationDate(): string {
    return DateUtils.toString(this.data?.publicationDate as Date) || '—';
  }

  get hideTooltip(): boolean {
    return !(this.editMode && this.checkExpiredDate());
  }

  get disable(): boolean {
    return !this.data?.publicationDate;
  }

  get composingGrade(): boolean {
    return this.data.composingGrade ?? false;
  }

  set composingGrade(cGrade: boolean) {
    this.data.composingGrade = cGrade;
  }

  created() {
    this.setDates();
  }

  mounted() {
    this.initialRecoveryDeliveryDate = this.data.recoveryDeliveryDate
      ? DateUtils.toString(this.data.recoveryDeliveryDate)
      : '';
  }

  private setDates() {
    const { deliveryDate, recoveryDeliveryDate } = this.data;

    this.deliveryDate = DateUtils.toString(deliveryDate as Date);
    this.recoveryDeliveryDate = DateUtils.toString(recoveryDeliveryDate as Date);

    this.checkInput(this.deliveryDate, DELIVERY);
    this.checkInput(this.recoveryDeliveryDate, RECOVERY);
  }

  get hasDeliveryPublished(): boolean {
    return Boolean(this.data?.hasDeliveryPublished);
  }

  public checked(value: boolean) {
    this.data.checked = value;

    if (!value) {
      this.cleanDates();
      this.emitDataChange();
      return;
    }

    if (!this.deliveryDate.length || !this.recoveryDeliveryDate.length) {
      this.invalidDate = true;
      EventBus.$emit(INVALID_DATE, this.invalidDate);
      return;
    }

    [DELIVERY, RECOVERY].forEach((i) => this.saveDate(i));
  }

  public checkInput(input: string, date: DateType) {
    clearTimeout(this.debounce);

    const dateActions = {
      DELIVERY: (i: string, toValidate = true) => (
        this.deliveryDate = toValidate ? this.validateDate(i) : i
      ),
      RECOVERY: (i: string, toValidate = true) => (
        this.recoveryDeliveryDate = toValidate ? this.validateDate(i) : i
      ),
    };

    if (input.length && input.length < 8) {
      dateActions[date](input, false);
      this.invalidDate = true;
      EventBus.$emit(INVALID_DATE, this.invalidDate);
      clearTimeout(this.debounce);
      return;
    }

    this.debounce = setTimeout(() => {
      dateActions[date](input);
      this.saveDate(date);
    }, 500);
  }

  private validateDate(date: string): string {
    return DateUtils.formatDate(date, daysOffset);
  }

  public newDataEntrega(offset: number): string {
    return DateUtils.setMinDate(offset).toLocaleDateString('pt-BR', { timeZone: 'GMT' });
  }

  public checkExpiredDate() {
    return DateUtils.checkExpiredDate(this.deliveryDate);
  }

  public dateReplace(data: string) {
    return data.replace(/\//g, '');
  }

  public format(data: string) {
    return `${data.slice(2, 4)}/${data.slice(0, 2)}/${data.slice(4)}`;
  }

  public dateBuilder(data: string, formatedData: string) {
    return data.length && data !== '—' ? new Date(formatedData) : undefined;
  }

  private saveDate(date: DateType) {
    const dateTranslator = {
      DELIVERY: this.deliveryDate,
      RECOVERY: this.recoveryDeliveryDate,
    };

    const setDateProperty = {
      DELIVERY: (replacedDate: string, formatedDate: string) => (
        this.data.deliveryDate = this.dateBuilder(replacedDate, formatedDate)
      ),
      RECOVERY: (replacedDate: string, formatedDate: string) => (
        this.data.recoveryDeliveryDate = this.dateBuilder(replacedDate, formatedDate)
      ),
    };

    const replacedDate = this.dateReplace(dateTranslator[date]);
    const formatedDate = this.format(replacedDate);
    setDateProperty[date](replacedDate, formatedDate);

    this.invalidDate = false;
    this.emitDataChange(this.invalidDate);
  }

  public cleanDates() {
    this.deliveryDate = '';
    this.data.deliveryDate = undefined;
    this.recoveryDeliveryDate = this.initialRecoveryDeliveryDate;
    this.data.recoveryDeliveryDate = undefined;
  }

  public emitDataChange(invalidDate = false) {
    this.invalidDate = invalidDate;
    EventBus.$emit(INVALID_DATE, invalidDate);
    this.$emit('change', this.data);
  }

  public toggleComposingGrade() {
    if (!this.data.checked || this.disable) return;

    this.composingGrade = !this.composingGrade;
    this.emitDataChange(this.invalidDate);
  }
}
