
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import SimpleHeaderType from
  '@/components/DynamicTable/DynamicTableHeaderTypes/SimpleHeaderType.vue';
import FilterHeaderType from
  '@/components/DynamicTable/DynamicTableHeaderTypes/FilterHeaderType.vue';

import { DTableHeaderBody } from '@/models';

const {
  SIMPLE_HEADER_TYPE,
  FILTER_HEADER_TYPE,
} = DTableHeaderBody;

const tableHeaderBody: {
  [index: string]:
  VueConstructor;
} = {
  [SIMPLE_HEADER_TYPE]: SimpleHeaderType,
  [FILTER_HEADER_TYPE]: FilterHeaderType,
};

@Component({
  name: 'DynamicTableBodyFactory',
})
export default class DynamicTableHeaderBodyFactory extends Vue {
  public static getTableHeaderBody(type: DTableHeaderBody): VueConstructor {
    return tableHeaderBody[type];
  }
}
