
import { Component, Prop, Vue } from 'vue-property-decorator';

import { SpecialDropdownData, SpecialDropdownEvents } from '@/models';

import { EventBus } from '@/utils';

const { SELECT } = SpecialDropdownEvents;

@Component({
  name: 'SpecialDropdown',
})
export default class SpecialDropdown extends Vue {
 @Prop({ default: [] }) data: SpecialDropdownData[]
 @Prop({ default: '' }) placeholder: string

  public display = false;
  private textToDisplay: string | null = null

  get dropdownPlaceholder(): string {
    return this.textToDisplay ?? this.placeholder;
  }

  set dropdownPlaceholder(text: string) {
    this.textToDisplay = text;
  }

  public toggle() {
    this.display = !this.display;
  }

  public select(item: string, value: number) {
    this.dropdownPlaceholder = item;
    EventBus.$emit(SELECT, value);
    this.$emit('select', { item, value });
    this.toggle();
  }
}

