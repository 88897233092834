
import { Component, Vue } from 'vue-property-decorator';
import SpecialDropdown from '@/components/shared/SpecialDropdown.vue';
import {
  SpecialDropdownData,
  ReportFilterEvents,
  Months,
  ReportFilterParametersEnum,
  ReportFilterData,
} from '@/models';
import { EventBus } from '@/utils';

const { ALL_SELECTED } = ReportFilterEvents;

@Component({
  name: 'ReportFilter',
  components: {
    SpecialDropdown,
  },
})
export default class ReportFilter extends Vue {
  private filterData: ReportFilterData = {
    parameter: null,
    month: null,
    year: null,
  }

  public get monthData(): SpecialDropdownData[] {
    return Object.keys(Months).map((month: string) => ({
      name: month,
      value: Months[month as keyof typeof Months],
    }));
  }

  public get parameterData(): SpecialDropdownData[] {
    return Object.keys(ReportFilterParametersEnum).map((parameter: string) => ({
      name: parameter,
      value: ReportFilterParametersEnum[parameter as keyof typeof ReportFilterParametersEnum],
    }));
  }

  public get yearData(): SpecialDropdownData[] {
    const currentYear = new Date().getFullYear();
    const data = [];

    for (let x = 2021; x <= currentYear; x++) {
      data.push({
        name: String(x),
        value: String(x),
      });
    }

    return data;
  }

  select(key: keyof ReportFilterData, { value }: SpecialDropdownData) {
    this.filterData[key] = value;

    if (this.checkAllSelected()) EventBus.$emit(ALL_SELECTED, this.filterData);
  }

  private checkAllSelected(): boolean {
    let allSelected = true;

    Object.keys(this.filterData).forEach((key) => {
      if (!this.filterData[key as keyof ReportFilterData]) allSelected = false;
    });

    return allSelected;
  }
}
