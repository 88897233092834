
import { Component, Prop, Vue } from 'vue-property-decorator';

import Eye from '@/assets/icons/Eye.svg';
import ChevronDown from '@/assets/icons/ChevronDown.svg';
import VerticalEllipsis from '@/assets/icons/VerticalEllipsis.svg';

import GradeBox from '@/components/GradeBox/GradeBox.vue';
import Pill from '@/components/shared/Pill.vue';
import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';

import {
  RouteName,
  DeliveryStatusColor,
  DeliveryStatusText,
  DeliveryStatus,
  FloatingMenuEvents,
  FloatingMenuContent,
  FloatingMenuModel,
  FloatingMenuPosition,
} from '@/models';
import { DeliveryState } from '@/models/store';

import { AccordionUtils } from '@/utils/AccordionUtils';
import { DateUtils, GradeUtils } from '@/utils';

const { DRAFT, NOT_STARTED, EXPIRED } = DeliveryStatus;
const { PUBLISHED_ASSIGNMENT } = RouteName;
const { DELETE } = FloatingMenuEvents;
const { SIMPLE_TYPE } = FloatingMenuContent;
const { LEFT } = FloatingMenuPosition;

@Component({
  name: 'DeliveryGrade',
  components: {
    Eye,
    ChevronDown,
    VerticalEllipsis,
    GradeBox,
    Pill,
    FloatingMenu,
    DynamicTable: () => import('@/components/DynamicTable/DynamicTable.vue'),
  },
})
export default class DeliveryGrade extends Vue {
  @Prop() data: DeliveryState;

  $refs: {
    expandableElement: HTMLDivElement;
  };

  public expanded = false;
  public DRAFT = DRAFT;
  public DeliveryStatusColor = DeliveryStatusColor;
  public DeliveryStatusText = DeliveryStatusText;
  public PUBLISHED_ASSIGNMENT = PUBLISHED_ASSIGNMENT;

  get deliveryMenu(): FloatingMenuModel {
    return {
      tip: true,
      hover: true,
      position: LEFT,
      data: [
        {
          type: SIMPLE_TYPE,
          id: this.data.id ?? 0,
          text: 'Excluir assignment',
          action: DELETE,
        },
      ],
    };
  }

  get deliveryDate(): string {
    const date = this.data.deliveryDate
      ?? this.data.recoveryDeliveryDate
      ?? this.data.aditionalDeliveryDate;
    const returnDate = date ? DateUtils.toString(date) : '—';
    return returnDate;
  }

  get publicationDate(): string {
    return this.data.publicationDate ? DateUtils.toString(this.data.publicationDate) : '—';
  }

  get grade(): string {
    const grade = this.data.aditionalGrade
      ?? this.data.recoveryGrade
      ?? this.data.grade;
    return GradeUtils.toGradeString(grade as number);
  }

  get showEllipsis(): boolean {
    return Boolean((this.data.status !== NOT_STARTED
    && !(this.data.status === EXPIRED && this.data.previousStatus === NOT_STARTED))
    || this.data.deliveryDate);
  }

  public toggleExpansion(): void {
    this.expanded = !this.expanded;
    AccordionUtils.toggleExpandableElement(this.$refs.expandableElement, this.expanded, 500);
  }

  public resizeAccordion(): void {
    AccordionUtils.toggleExpandableElement(this.$refs.expandableElement, this.expanded, 500);
  }
}
