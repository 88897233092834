
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'NoData',
})

export default class NoData extends Vue {
  @Prop({ default: '' }) text: string;
}
