
import { Component, Prop, Vue } from 'vue-property-decorator';

import MaskInput from '@/components/shared/MaskInput.vue';
import {
  DataConfirmation,
  GradeFormModel,
  ModalDataTransfer,
  ModalTipos,
} from '@/models';
import { DateUtils, EventBus, GradeUtils } from '@/utils';
import { ModalService } from '@/services';
import GradeConfirm from '../AssignmentObject/GradeConfirm.vue';

@Component({
  name: 'DeliveryGradesForm',
  components: {
    MaskInput,
  },
})
export default class DeliveryGradesForm extends Vue {
  @Prop() data: GradeFormModel;
  @Prop({ default: false }) disable: boolean;

  private gradeFormData: GradeFormModel;
  private initialGrade: number | undefined;
  private debounce: number;
  private aditionalDateDebounce: number;

  get gradeForm(): GradeFormModel {
    return this.gradeFormData ?? this.data;
  }

  set gradeForm(data: GradeFormModel) {
    this.gradeFormData = data;
  }

  get dueDate(): string {
    const ptBRDate = DateUtils.toString(this.gradeForm.dueDate)
      ?? '—';

    return ptBRDate;
  }

  set dueDate(date: string) {
    this.gradeForm.dueDate = new Date(date);
  }

  mounted() {
    this.initialGrade = GradeUtils.toDecimalNumber(this.data.grade);
  }

  public chosenResponse(response: boolean): void {
    EventBus.$off(ModalTipos.CONFIRM_GRADE, this.chosenResponse);

    const { maxGrade } = this.gradeForm;
    const inputNumber = response ? 0 : maxGrade;

    if (!response) this.continue(Number(inputNumber));
  }

  public openModal(): void {
    const data: DataConfirmation = {
      component: GradeConfirm,
      allowText: 'CONFIRMAR',
      denyText: 'CANCELAR',
      confirmEvent: ModalTipos.CONFIRM_GRADE,
    };

    const modal: ModalDataTransfer = {
      fechavel: false,
      component: ModalTipos.CONFIRM_GRADE,
      props: { data },
    };

    EventBus.$on(ModalTipos.CONFIRM_GRADE, this.chosenResponse);
    ModalService.abrirModal(modal);
  }

  public checkGrade(input: string): void {
    clearTimeout(this.debounce);

    this.debounce = setTimeout(() => {
      const { maxGrade } = this.gradeForm;
      const inputNumber = Number(input) / 100;

      if (maxGrade && inputNumber > maxGrade) {
        this.openModal();
      }

      this.continue(inputNumber);
    });
  }

  private continue(input: number): void {
    let inputNumber = Number(input);
    if (inputNumber > 10) inputNumber = 10;

    this.gradeForm.grade = GradeUtils.toGradeString(Number(inputNumber));
    this.gradeForm.conceptGrade = GradeUtils.numberToConcept(Number(inputNumber));
    this.gradeForm.requiredError = this.initialGrade !== undefined && inputNumber === 0;

    this.$emit('change', this.gradeForm);
  }

  public validateAditionalDate(input: string): void {
    clearTimeout(this.aditionalDateDebounce);

    if (!input || (input.length && input.length < 8)) return;

    this.dueDate = '0';
    this.aditionalDateDebounce = setTimeout(() => {
      this.dueDate = this.validateDate(input);
      this.$emit('change', this.gradeForm);
    });
  }

  private validateDate(date: string): string {
    let dateToValidate = date;
    if (DateUtils.checkExpiredDate(date)) {
      dateToValidate = DateUtils.toString(DateUtils.setMinDate(0));
    }
    return DateUtils.toISODateString(dateToValidate);
  }
}
