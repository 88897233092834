<template>
  <TheMask
    :placeholder="placeholder"
    :mask="mask"
    :value="value"
    :disabled="disable"
    @input="onChange"
  />
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  name: 'MaskInput',
  data() {
    return {};
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      default: '',
      type: String,
    },
    placeholder: {
      required: true,
      default: '',
      type: String,
    },
    mask: {
      required: true,
      default: '',
      type: String,
    },
    disable: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    TheMask,
  },
  methods: {
    onChange(text) {
      this.$emit('input', text);
    },
  },
};
</script>
