
import { Component, Model, Mixins } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';

import QuestionCircle from '@/assets/icons/QuestionCircle.svg';
import Pencil from '@/assets/icons/Pencil.svg';

import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import DeliveryGradesForm from '@/components/shared/DeliveryGradesForm.vue';

import {
  GradeFormModel,
  DeliveryGradesFormHeader,
  Delivery,
  CommentModel,
  SaveGradeConfirmData,
  DeliveryStatus,
  ModalTipos,
  DataConfirmation,
} from '@/models';
import { DeliveryState } from '@/models/store';

import { DateUtils, EventBus, GradeUtils } from '@/utils';
import PublicationModalFlow from '@/mixins/PublicationModalFlow';
import SaveConfirm from './SaveConfirm.vue';

const { REGULAR, RECOVERY, ADITIONAL } = DeliveryGradesFormHeader;
const { SAVE_GRADE, CONFIRMACAO } = ModalTipos;
const {
  PUBLISHED,
  IN_RECOVERY,
  AWAITING_EVALUATION,
  PENDING_STUDENT_CORRECTION,
  EXPIRED,
} = DeliveryStatus;

@Component({
  name: 'GradeBox',
  components: {
    ButtonDefault,
    QuestionCircle,
    Pencil,
    DeliveryGradesForm,
    Tooltip,
    CheckboxInput,
  },
  computed: {
    ...mapGetters('user', {
      canSetScore: 'canSetScore',
    }),
    ...mapGetters('deliveries', {
      requesting: 'requesting',
    }),
  },
  methods: {
    ...mapActions('deliveries', {
      resetDelivery: 'resetDelivery',
      updateDeliveriesList: 'updateDeliveriesList',
      saveDeliveryAvaliation: 'saveDeliveryAvaliation',
      editDeliveryAvaliation: 'editDeliveryAvaliation',
    }),
  },
})
export default class GradeBox extends Mixins(PublicationModalFlow) {
  @Model() data: DeliveryState;
  public DateUtils = DateUtils;
  public commentOpened = false;
  public aditionalDate = false;
  public ADITIONAL = ADITIONAL;

  private modalData: DataConfirmation<SaveGradeConfirmData> = {
    component: SaveConfirm,
    allowText: 'CONFIRMAR',
    denyText: 'CANCELAR',
    confirmEvent: SAVE_GRADE,
  };

  private updateDeliveriesList: (delivery: DeliveryState) => void;
  private resetDelivery: (id: number) => void;
  private saveDeliveryAvaliation: (deliveryState: DeliveryState) => Promise<Delivery>;
  private editDeliveryAvaliation: (deliveryState: DeliveryState) => Promise<Delivery>;

  get disable(): boolean {
    return !this.data.hasChanges;
  }

  get showMore(): boolean {
    return Boolean(this.data.comments?.length);
  }

  get disableAditionalCheckbox(): boolean {
    return !this.data.enableAditionalDate;
  }

  mounted() {
    EventBus.$on(SAVE_GRADE, this.checkConfirmation);
    this.aditionalDate = Boolean(this.data.aditionalDueDate);
  }

  beforeLeave() {
    EventBus.$off(SAVE_GRADE, this.checkConfirmation);
  }

  public editComment(comment: CommentModel): void {
    this.data.comment = comment.comment;
    this.data.commentIdToEdit = comment.id;
  }

  public saveChanges(gradeForm: GradeFormModel): void {
    switch (gradeForm.header) {
      case REGULAR:
        this.data.grade = GradeUtils.toDecimalNumber(gradeForm.grade);
        this.data.conceptGrade = gradeForm.conceptGrade;
        break;
      case RECOVERY:
        this.data.recoveryGrade = GradeUtils.toDecimalNumber(gradeForm.grade);
        this.data.conceptRecoveryGrade = gradeForm.conceptGrade;
        break;
      case ADITIONAL:
        this.data.aditionalGrade = GradeUtils.toDecimalNumber(gradeForm.grade);
        this.data.conceptAdictionalGrade = gradeForm.conceptGrade;
        this.data.aditionalDueDate = gradeForm.dueDate;
        break;
      default:
        break;
    }
    this.updateDelivery();
  }

  public updateDelivery(): void {
    this.updateDeliveriesList(this.data);
  }

  public cancel(): void {
    delete this.data.commentIdToEdit;
    this.resetDelivery(this.data.id as number);
  }

  public confirmModal(): void {
    this.modalData.phrase = {
      id: this.data.id as number,
      from: this.data.status as DeliveryStatus,
      to: this.updateStatus(),
      phrase: 'Você tem certeza que deseja confirmar\nas alterações?',
    };

    this.openModal<SaveGradeConfirmData>(CONFIRMACAO, this.modalData);
  }

  private updateStatus(): DeliveryStatus {
    const {
      grade,
      recoveryGrade,
      aditionalGrade,
      minimumGrade,
      deliveryDate,
      recoveryDeliveryDate,
      aditionalDeliveryDate,
      status,
      comment,
      previousStatus,
      aditionalDueDate,
    } = this.data;

    switch (status) {
      case PUBLISHED:
        if ((grade && minimumGrade && grade < minimumGrade && !recoveryGrade)
        || (recoveryGrade && minimumGrade && recoveryGrade < minimumGrade)) {
          return IN_RECOVERY;
        }
        break;
      case IN_RECOVERY:
        if ((grade && minimumGrade && grade >= minimumGrade)
        || (recoveryGrade && minimumGrade && recoveryGrade >= minimumGrade)) {
          return PUBLISHED;
        }
        break;
      case AWAITING_EVALUATION:
        if (comment?.length && !grade && !recoveryGrade) {
          return PENDING_STUDENT_CORRECTION;
        }

        if (minimumGrade) {
          if ((deliveryDate && !recoveryGrade && grade && grade < minimumGrade)
          || (recoveryDeliveryDate && recoveryGrade && recoveryGrade < minimumGrade)
          || (aditionalDeliveryDate && aditionalGrade && aditionalGrade < minimumGrade)) {
            return IN_RECOVERY;
          }

          if ((deliveryDate && grade && grade >= minimumGrade)
          || (recoveryDeliveryDate && recoveryGrade && recoveryGrade >= minimumGrade)
          || (aditionalDeliveryDate && aditionalGrade && aditionalGrade >= minimumGrade)) {
            return PUBLISHED;
          }
        }
        break;
      case EXPIRED:
        return (aditionalDueDate ? previousStatus : status) as DeliveryStatus;
      default:
        return status as DeliveryStatus;
    }
    return status as DeliveryStatus;
  }

  private checkConfirmation(response: boolean): void {
    if (this.modalData.phrase?.id === this.data.id) {
      if (response) this.save();
      else this.cancel();
      this.modalData.phrase = undefined;
    }
  }

  public save(): void {
    if (this.data && this.data.commentIdToEdit) {
      this.editDeliveryAvaliation(this.data)
        .finally(this.resizeAccordion);
    } else {
      this.data.status = this.updateStatus();
      this.saveDeliveryAvaliation(this.data).catch(alert)
        .finally(this.resizeAccordion);
    }
  }

  public toggleAditionalDate(show: boolean): void {
    this.aditionalDate = show;
    this.resizeAccordion();
    if (!show) this.cancel();
  }

  private resizeAccordion() {
    setTimeout(() => {
      this.$emit('resize');
    });
  }
}
