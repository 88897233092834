
import { Component, Prop, Vue } from 'vue-property-decorator';

import SwitchOn from '@/assets/icons/SwitchOn.svg';
import SwitchOff from '@/assets/icons/SwitchOff.svg';

@Component({
  name: 'ToggleSwitch',
  components: {
    SwitchOn,
    SwitchOff,
  },
})

export default class ToggleSwitch extends Vue {
  @Prop({ default: false }) enabled: boolean;
  @Prop({ default: false }) disabled: boolean;
}
