
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import DynamicTableHeader from '@/components/DynamicTable/DynamicTableHeader.vue';
import DynamicTableBodyFactory from '@/components/DynamicTable/DynamicTableBodyFactory.vue';

import {
  DTable,
  DTableBody,
  Class,
  DTableEvents,
} from '@/models';

import { EventBus } from '@/utils';

const { SPINNER, NO_DATA } = DTableBody;
const { REQUEST_STARTED, REQUEST_ENDED } = DTableEvents;

@Component({
  name: 'DynamicTable',
  components: {
    DynamicTableHeader,
    DynamicTableBodyFactory,
  },
})

export default class DynamicTable extends Vue {
  @Prop() tableData: DTable;
  @Prop({ default: false }) editMode: boolean;

  private returnData: DTable;
  private bodyData: VueConstructor | null = DynamicTableBodyFactory.getTableBody(SPINNER);

  get body(): VueConstructor | null {
    return this.tableData.data.length
      ? DynamicTableBodyFactory.getTableBody(this.tableData.body)
      : this.bodyData;
  }

  set body(type: VueConstructor | null) {
    this.bodyData = type;
  }

  mounted() {
    this.subscribeToEvents();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }

  public appendChange(index: number, item: Class) {
    this.returnData = this.tableData;

    if (this.returnData.data.length) {
      this.returnData.data[index] = item;
    } else {
      this.returnData.data = [item];
    }

    this.$emit('change', this.returnData);
  }

  private subscribeToEvents() {
    EventBus.$on(REQUEST_STARTED, this.requestStarted);
    EventBus.$on(REQUEST_ENDED, this.requestEnded);
  }

  private unsubscribeToEvents() {
    EventBus.$off(REQUEST_STARTED, this.requestStarted);
    EventBus.$off(REQUEST_ENDED, this.requestEnded);
  }

  private requestStarted() {
    this.body = DynamicTableBodyFactory.getTableBody(SPINNER);
  }

  private requestEnded() {
    const tableBody = this.tableData.data.length ? this.tableData.body : NO_DATA;
    this.body = DynamicTableBodyFactory.getTableBody(tableBody);
  }
}
