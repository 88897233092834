
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import {
  ChipType,
  FilterData,
  FilterToField,
  FilterToHeader,
  FloatingMenuModel,
  FloatingMenuContent,
  FloatingMenuEvents,
  FloatingMenuItem,
  FilterEnum,
} from '@/models';

import SortUp from '@/assets/icons/SortUp.svg';
import SortDown from '@/assets/icons/SortDown.svg';
import FilterIcon from '@/assets/icons/FilterIcon.svg';
import CloseCircle from '@/assets/icons/CloseCircle.svg';
import ChevronDown from '@/assets/icons/ChevronDown.svg';

import Chip from '@/components/shared/Chip.vue';
import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';

import {
  ArrayUtils,
  EnumUtils,
  EventBus,
  FilterUtils,
  StringUtils,
} from '@/utils';

const { BUTTON_TYPE, CHECKBOX_TYPE } = FloatingMenuContent;
const { CHECK, APPLY } = FloatingMenuEvents;

@Component({
  name: 'DynamicTableHeaderFilterList',
  components: {
    SortUp,
    SortDown,
    FilterIcon,
    CloseCircle,
    ChevronDown,
    Chip,
    FloatingMenu,
  },
})
export default class DynamicTableHeaderFilterList extends Vue {
  @Prop({}) filterList: FilterData;

  public chipListData: ChipType[] = [];
  public menuData: { [key: string]: FloatingMenuModel } = {}

  public clearChip: ChipType = {
    id: 0,
    name: 'Limpar todos',
  };

  get chipList(): ChipType[] {
    return this.chipListData;
  }

  set chipList(list: ChipType[]) {
    this.chipListData = list;
  }

  get menu(): { [key: string]: FloatingMenuModel } {
    return this.menuData;
  }

  set menu(data: { [key: string]: FloatingMenuModel }) {
    this.menuData = data;
  }

  mounted() {
    this.subscribeToEvents();
    this.convertToChipType(this.filterList);
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }

  private subscribeToEvents() {
    EventBus.$on(APPLY, this.apply);
  }

  private unsubscribeToEvents() {
    EventBus.$on(APPLY, this.apply);
  }

  public clearAll() {
    this.$emit('clearAll');
  }

  @Watch('filterList')
  public convertToChipType(data: FilterData): void {
    this.menu = {};
    this.chipList = [];

    Object.keys(data).forEach((key: string) => {
      const header = EnumUtils.fromFirstEnumToSecondEnum<
      typeof FilterToField,
      typeof FilterToHeader
    >(key, FilterToField, FilterToHeader);
      const { filters, desc } = data[key];
      const keyString = EnumUtils
        .fromFirstEnumToSecondEnum<typeof FilterToField, typeof FilterEnum>(
          key,
          FilterToField,
          FilterEnum,
        ) as string;

      if ((filters as string[])?.length > 1) {
        this.menu = {
          ...this.menu,
          [key]: {
            data: [
              ...(filters as string[]).map((d: string, idx: number) => ({
                type: CHECKBOX_TYPE,
                id: idx,
                text: FilterUtils.formatFilters(keyString, d),
                action: CHECK,
                checked: true,
                keyString: key,
                value: d,
              })),
              {
                type: BUTTON_TYPE,
                id: (filters as string[]).length,
                text: 'Aplicar',
                keyString: key,
                action: APPLY,
              },
            ],
            tip: true,
            hover: false,
            showMenu: false,
            keyString: key,
            openOnlyOne: true,
          } as FloatingMenuModel,
        };
      }

      if (typeof desc === 'boolean') {
        this.chipList = [...this.chipListData ?? [], new ChipType(
          `${StringUtils.capitalize(header as string)}: ${
            desc ? 'ordem decrescente' : 'ordem crescente'
          }`,
          `${key}-desc`,
        )];
      }

      if (filters?.length) {
        this.chipList = [
          ...this.chipListData ?? [],
          new ChipType(
            `${StringUtils.capitalize(header as string)}: ${
            (filters as string[])?.length > 1
              ? 'Multiplos Itens'
              : FilterUtils.formatFilters(keyString, ArrayUtils.lastItem(filters))
            }`,
            key,
          )];
      }
    });
  }

  public removeFilter(index: string) {
    const keys = index.split('-');

    const { filters, desc } = this.filterList[keys[0]];

    const data = keys[1] === 'desc' ? { desc } : { filters };

    const filterToRemove: FilterData = { [keys[0]]: data };
    this.$emit('remove', filterToRemove);
  }

  private apply(data: FloatingMenuItem) {
    const { keyString } = data;
    const removeItem = {
      [keyString as string]: {
        filters: this.menuData[keyString as string]?.data
          .reduce((acc: string[], cur: FloatingMenuItem) => {
            const accumulator = acc;
            if (!cur.checked && cur.value) accumulator.push(cur.value as string);
            return accumulator;
          }, []),
      },
    };

    this.$emit('remove', removeItem);
  }
}
