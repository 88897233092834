var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{staticClass:"turma-container",attrs:{"to":{
    name: _vm.ASSIGNMENT_OBJECT_ASSIGNMENT,
    params: {
      trabalhoId: _vm.assignmentObjectId,
      turmaId: _vm.dataId,
    },
  }}},[_c('span',{staticClass:"turma-empty"}),_c('span',{staticClass:"turma-name"},[_vm._v(_vm._s(_vm.data.name))]),_c('span',{staticClass:"turma-publication"},[_vm._v(_vm._s(_vm.getDate('publicationDate')))]),_c('span',{staticClass:"turma-delivery"},[_vm._v(_vm._s(_vm.getDate('deliveryDate')))]),_c('span',{staticClass:"turma-recovery"},[_vm._v(_vm._s(_vm.getDate('recoveryDeliveryDate')))]),_c('LongArrowRight',{staticClass:"turma-icon"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }