
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import Tooltip from '@/components/shared/Tooltip.vue';
import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';

import {
  DTableHeader,
  DTableHeaderBody,
  DTableEvents,
  FilterData,
} from '@/models';

import { CssUtils, EventBus, FilterUtils } from '@/utils';

import SortUpIcon from '../shared/SortUpIcon.vue';
import SortDownIcon from '../shared/SortDownIcon.vue';
import DynamicTableHeaderBodyFactory from './DynamicTableHeaderFactory.vue';
import DynamicTableHeaderFilterList from './DynamicTableHeaderFilterList.vue';

const { ON_FILTER, CLEAR_ALL_FILTERS, REMOVE_FILTER } = DTableEvents;

@Component({
  name: 'DynamicTableHeader',
  components: {
    Tooltip,
    FloatingMenu,
    SortDownIcon,
    SortUpIcon,
    DynamicTableHeaderFilterList,
  },
})

export default class DynamicTableHeader extends Vue {
  @Prop({ default: [] }) headers: DTableHeader[];
  @Prop({}) headerStyle: { [ key: string ]: string | number | number[] };

  public filters: FilterData;

  get hasFilter(): boolean {
    const { filter } = this.$route.query;
    if (filter) this.filters = FilterUtils.toObject(filter as string);

    return this.filters && Boolean(Object.keys(this.filters).length)
      && Boolean(this.headers.filter((header: DTableHeader) => header.hasFilter).length);
  }

  public getBody(type: DTableHeaderBody): VueConstructor | null {
    return DynamicTableHeaderBodyFactory.getTableHeaderBody(type);
  }

  public buildClasses(header: DTableHeader): string {
    let classes = 'table-header__cell';

    if (header.grow) classes += ' table-header__cell--grow';
    if (header.width) classes += ` table-header__cell--${header.width}`;
    if (header.textAlign) classes += ` table-header__cell--text-${header.textAlign}`;

    return classes;
  }

  public applyFilter(filter: FilterData) {
    this.filters = { ...this.filters, ...filter };
    Object.keys(this.filters).forEach((key: string) => {
      if ((!(this.filters[key].filters as string[])?.length
      && JSON.stringify(this.filters[key].desc))) {
        delete this.filters[key].filters;
      }

      if (!((this.filters[key].filters as string[])?.length
      || JSON.stringify(this.filters[key].desc))) {
        delete this.filters[key];
      }
    });

    EventBus.$emit(ON_FILTER, this.filters);
  }

  public cssVars(data: {
    [key: string]: string | number | number[];
    }): {[key: string]: string} | undefined {
    if (!data) return undefined;

    return CssUtils.buildVars(data);
  }

  public removeFilter(filterToRemove: FilterData) {
    Object.keys(filterToRemove).forEach((key: string) => {
      if (filterToRemove[key].filters) {
        this.filters[key].filters = (this.filters[key].filters as string[])
          .filter((f: string) => (!(filterToRemove[key].filters as string[]).includes(f)));

        if (!(this.filters[key].filters as string[]).length) delete this.filters[key].filters;

        if (!((this.filters[key].filters as string[])?.length
        || JSON.stringify(this.filters[key].desc))) {
          delete this.filters[key];
        }
      } else {
        delete this.filters[key].desc;

        if (!this.filters[key].filters) delete this.filters[key];
      }
    });

    this.filters = { ...this.filters };

    EventBus.$emit(REMOVE_FILTER, this.filters);
  }

  public clearAllFilters() {
    this.filters = {};
    EventBus.$emit(CLEAR_ALL_FILTERS);
  }
}
