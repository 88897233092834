
import { Component, Prop, Vue } from 'vue-property-decorator';

import LongArrowRight from '@/assets/icons/LongArrowRight.svg';

import { RouteName, Class } from '@/models';
import { DateUtils } from '@/utils';
import { DynamicTableService } from '@/services';

const { ASSIGNMENT_OBJECT_ASSIGNMENT } = RouteName;

@Component({
  name: 'AssignmentObjectTurma',
  components: {
    LongArrowRight,
  },
})

export default class AssignmentObjectTurma extends Vue {
  @Prop() data: Class;
  @Prop({ default: false }) editMode: boolean;
  @Prop({ default: false }) darkMode: boolean;

  public ASSIGNMENT_OBJECT_ASSIGNMENT = ASSIGNMENT_OBJECT_ASSIGNMENT;

  mounted() {
    if (this.data) DynamicTableService.requestEnded();
  }

  get assignmentObjectId(): string {
    return this.data.assignmentObjectId?.toString() ?? '';
  }

  get dataId(): string {
    return this.data.id?.toString() ?? '';
  }

  public getDate(key: keyof Class): string {
    return this.data[key]
      ? DateUtils.toString(this.data[key] as Date)
      : '—';
  }
}
